import * as Cleaner from '../schemas/inductions/Kmart/Cleaner.json'
import * as Contractor from '../schemas/inductions/Kmart/Contractor.json'
import * as DeliveryDriver from '../schemas/inductions/Kmart/DeliveryDriver.json'
import * as SupplierMechandiser from '../schemas/inductions/Kmart/SupplierMechandiser.json'
import * as TeamMember from '../schemas/inductions/Kmart/TeamMember.json'
import * as Visitor from '../schemas/inductions/Kmart/Visitor.json'

export default {
    type: 'Kmart Induction',
    schemas: {
        'Kmart Cleaner': Cleaner,
        'Kmart Contractor': Contractor,
        'Kmart Delivery Driver': DeliveryDriver,
        'Kmart Supplier Merchandiser': SupplierMechandiser,
        'Kmart Team member': TeamMember,
        'Kmart Visitor': Visitor
    }
}