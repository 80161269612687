import * as ColesTeamMember from '../schemas/inductions/ColesExpress/ColesTeamMember.json'
import * as Contractor from '../schemas/inductions/ColesExpress/Contractor.json'
import * as DeliveryDriver from '../schemas/inductions/ColesExpress/DeliveryDriver.json'
import * as SecurityOfficer from '../schemas/inductions/ColesExpress/SecurityOfficer.json'
import * as SupplierMerchandiser from '../schemas/inductions/ColesExpress/SupplierMerchandiser.json'
import * as TimeCheckin from '../schemas/inductions/ColesExpress/TimeCheckin.json'
import * as UnregisterContractor from '../schemas/inductions/ColesExpress/UnregisterContractor.json'
import * as VivaContractor from '../schemas/inductions/ColesExpress/VivaContractor.json'
import * as Visitor from '../schemas/inductions/ColesExpress/Visitor.json'

export default {
    type: 'Coles Express Induction',
    schemas: {
        'Coles Team Member': ColesTeamMember,
        'Coles Contractor': Contractor,
        'Coles Delivery Driver': DeliveryDriver,
        'Coles Security Officer': SecurityOfficer,
        'Coles Supplier Merchandiser': SupplierMerchandiser,
        'Coles Time Check In': TimeCheckin,
        'Coles UnregisterContractor': UnregisterContractor,
        'Coles Viva Contractor': VivaContractor,
        'Coles Visitor': Visitor
    }
}