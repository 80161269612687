<template>
  <div>
    <NavigationSlot :show-back-arrow="!!selectedSchema" @remove-schema="removeSchema"/>
    <div v-if="!selectedSchema">
      <TitleBanner class="mb-8" title="Schema Validator"/>
      <SchemaSelector
          :heading="serviceFormSchemaType?.type"
          :schemas="serviceFormSchemaType?.schemas"
          @schema-selected="
            (name: string, schema, maps, equations) =>
              schemaSelected(name, schema, maps, equations, serviceFormSchemaType?.fullWidth)
          "
      />
      <div class="mt-10 flex flex-col">
        <h3 class="text-lg text-center mb-4">Customer specific forms</h3>
        <select class="w-11/12 block mx-auto mb-8" @change="(event) => customerSelected(event.target)">
          <option :value="null" disabled hidden selected>Select customer</option>
          <option v-for="type in customerSpecificTypes" :value="type" >{{ type }}</option>
        </select>
        <SchemaSelector
          v-if="selectedCustomer"
          :heading="selectedCustomer.type"
          :schemas="selectedCustomer.schemas"
          @schema-selected="
                (name: string, schema, maps, equations) =>
                  schemaSelected(name, schema, maps, equations, selectedCustomer?.fullWidth)
              "
        />
      </div>
    </div>
    <div v-else>
      <ServiceForm
          :form-is-full-width="formIsFullWidth"
          :schema-prop="selectedSchema"
          :schema-maps="schemaMaps"
          :schema-equations="schemaEquations"
          :service-form-name="selectedSchemaName"
          @remove-schema="removeSchema"
      />
    </div>
  </div>
</template>

<script lang="ts">
import serviceFormSchemas from './service-form-schemas'
import kmartInductionSchemas from "./kmart-induction-schemas";
import kmartPermitSchemas from "./kmart-permit-schemas";
import kmartSupplementalSchemas from "./kmart-supplemental-schemas";
import cduInductionSchemas from "./cdu-induction-schemas";
import colesExpressInductionSchemas from "./coles-express-induction-schemas";
import colesExpressSupplementalSchemas from "./coles-express-supplemental-schemas";
import ddhInductionSchemas from "./ddh-induction-schemas";
import officeworksInductionSchemas from "./officeworks-induction-schemas";
import officeworksPermitSchemas from "./officeworks-permit-schemas";
import westpacInductionSchemas from "./westpac-induction-schemas";
import westpacPermitSchemas from "./westpac-permit-schemas";
import spotCheckSchema from "./spot-check";
import hertzInductionSchemas from "./hertz-induction-schemas";
import {computed, defineComponent, ref} from "vue";
import TitleBanner from "./components/TitleBanner.vue";
import ServiceForm from "./views/ServiceForm.vue";
import NavigationSlot from "./components/NavigationSlot.vue";
import SchemaSelector from "./components/SchemaSelector.vue";
import { EquationsType, errorMessage, MapsType, SchemaType } from "@verifiedit/form-generator";
import ExamplePdf from './assets/8f4ff03b-8746-46a3-a23c-575c90b41f03/ExampleEvac.pdf'
import ExamplePdfImage from './assets/8f4ff03b-8746-46a3-a23c-575c90b41f03/1.png'

export default defineComponent({
  name: "App",

  components: {
    NavigationSlot,
    SchemaSelector,
    ServiceForm,
    TitleBanner,
  },

  setup() {
    const selectedSchema = ref<(SchemaType & errorMessage)[] | null>(null)
    const schemaMaps = ref<MapsType>({})
    const schemaEquations = ref<EquationsType>({})
    const selectedSchemaName = ref('')
    const selectedCustomer = ref<SchemasType|null>(null)

    function transformSchema(schemaType: { type: string, schemas: object }) {
      const transformed: string[] = []
      Object.values(schemaType.schemas).forEach(schema => {
        if (transformed.includes(schema['$id'])) {
          return
        }
        if (schema.template?.components !== undefined) {
          schema.template.components.forEach((component: SchemaType) => {
            component.errorMessage = ''
            replaceDynamicInductionData(component, schema.template.components)
          })
          transformed.push(schema['$id'])
          return
        }
        schema.data.schema.forEach((component: SchemaType) => {
          component.errorMessage = ''
          replaceDynamicInductionData(component, schema.data.schema)
        })
        transformed.push(schema['$id'])
      })
    }

    function addFakeStaticDocuments(parent: string, amount: number, schema: SchemaType[]) {
      const component = {
        component: 'StaticFileLink',
        parent: parent,
        show: true,
        identifier: Math.random().toString(),
        resource: '',
        resourceFunction: (resource: string) => { return { origin: '' } },
        label: 'Document name'
      }
      Array(amount).fill(component).forEach(component => schema.push(component))
    }

    function addFakeHazardDocument(schema: SchemaType[]) {
      const component = {
        component: 'StaticText',
        parent: 'hazardousMaterialRegister',
        show: true,
        identifier: Math.random().toString(),
        content: 'There is no Hazardous Material Register for this site. Please speak to the site representative.'
      }
      schema.push(component)
    }

    function replaceDynamicInductionData(component: SchemaType, schema: SchemaType[]) {
      if (component.identifier === 'siteinfo' && component.content?.includes('{{')) {
        component.content =
            '#### Site Contact\n\n' +
            'Site contact name\n\n' +
            '#### Site Phone\n\n' +
            '9700 0011\n\n' +
            '#### Site Email\n\n' +
            'site-email@email.com\n\n' +
            '#### Site Hours\n\n' +
            '9am - 5pm'
      }

      if (component.identifier === 'siteDocuments') {
        addFakeStaticDocuments('siteDocuments', 2, schema)
      }

      if (component.identifier === 'customerDocuments') {
        addFakeStaticDocuments('customerDocuments', 1, schema)
      }

      if (component.document === 'hazard_document') {
        addFakeHazardDocument(schema)
      }

      if (component.identifier === 'diagramViewer') {
        component.resourceFunction = () => {
              return {
              images: [ExamplePdfImage],
              filename: 'ExamplePdf',
              origin: ExamplePdf
            }
          }
        component.show = true
      }
    }

    transformSchema(cduInductionSchemas)
    transformSchema(colesExpressInductionSchemas)
    transformSchema(ddhInductionSchemas)
    transformSchema(hertzInductionSchemas);
    transformSchema(kmartPermitSchemas)
    transformSchema(kmartInductionSchemas)
    transformSchema(kmartSupplementalSchemas)
    transformSchema(officeworksInductionSchemas)
    transformSchema(serviceFormSchemas)
    transformSchema(westpacInductionSchemas)
    transformSchema(officeworksPermitSchemas)
    transformSchema(westpacPermitSchemas)
    transformSchema(colesExpressSupplementalSchemas)
    transformSchema(spotCheckSchema)

    interface SchemasType {
      type: string
      schemas: object
      fullWidth?: boolean
    }

    const schemaTypes = computed<SchemasType[]>(() =>
       [
        {type: serviceFormSchemas.type, schemas: serviceFormSchemas.schemas},
        {type: cduInductionSchemas.type, schemas: cduInductionSchemas.schemas, fullWidth: true},
        {type: colesExpressInductionSchemas.type, schemas: colesExpressInductionSchemas.schemas, fullWidth: true},
        {type: colesExpressSupplementalSchemas.type, schemas: colesExpressSupplementalSchemas.schemas},
        {type: ddhInductionSchemas.type, schemas: ddhInductionSchemas.schemas, fullWidth: true},
        {type: hertzInductionSchemas.type, schemas: hertzInductionSchemas.schemas},
        {type: kmartInductionSchemas.type, schemas: kmartInductionSchemas.schemas, fullWidth: true},
        {type: kmartPermitSchemas.type, schemas: kmartPermitSchemas.schemas},
        {type: kmartSupplementalSchemas.type, schemas: kmartSupplementalSchemas.schemas},
        {type: officeworksInductionSchemas.type, schemas: officeworksInductionSchemas.schemas, fullWidth: true},
        {type: officeworksPermitSchemas.type, schemas: officeworksPermitSchemas.schemas},
        {type: westpacInductionSchemas.type, schemas: westpacInductionSchemas.schemas, fullWidth: true},
        {type: westpacPermitSchemas.type, schemas: westpacPermitSchemas.schemas},
        {type: spotCheckSchema.type, schemas: spotCheckSchema.schemas},
      ]
    )

    const serviceFormSchemaType = schemaTypes.value.find(schemaType => schemaType.type === 'Service forms')

    const customerSpecificTypes = computed(() =>
      schemaTypes.value.map(schemaType => {
        if (schemaType.type !== 'Service forms') {
          return schemaType.type
        }
      }).filter(type => !!type)
    )

    const formIsFullWidth = ref<boolean>(false)

    function schemaSelected(name: string, schema: (SchemaType & errorMessage)[], maps: MapsType, equations: EquationsType, fullWidth?: boolean | undefined) {
      selectedSchemaName.value = name
      selectedSchema.value = schema
      schemaMaps.value = maps ?? {}
      schemaEquations.value = equations ?? {}

      if (fullWidth) {
        formIsFullWidth.value = fullWidth
      }
    }

    function removeSchema() {
      selectedSchema.value = null
      formIsFullWidth.value = false
      selectedCustomer.value = null
    }

    function customerSelected(EventTarget:  EventTarget | null) {
      const type = (EventTarget as HTMLSelectElement)?.value
      const customerSchemas =  schemaTypes.value.find(schemaType => schemaType.type === type)
      if (customerSchemas) {
        selectedCustomer.value = customerSchemas
      }
    }

    return {
      customerSpecificTypes,
      customerSelected,
      schemaTypes,
      selectedCustomer,
      formIsFullWidth,
      kmartInductionSchemas,
      kmartSupplementalSchemas,
      schemaEquations,
      schemaMaps,
      schemaSelected,
      removeSchema,
      serviceFormSchemaType,
      selectedSchema,
      selectedSchemaName,
    }
  }
})

</script>

<style scoped>
</style>
