import * as Cleaner from '../schemas/inductions/Officeworks/Cleaner.json'
import * as Contractor from '../schemas/inductions/Officeworks/Contractor.json'
import * as SecurityGuard from '../schemas/inductions/Officeworks/SecurityGuard.json'
import * as SupplierMerchandiser from '../schemas/inductions/Officeworks/SupplierMerchandiser.json'
import * as UnregisterContractor from '../schemas/inductions/Officeworks/UnregisterContractor.json'
import * as Visitor from '../schemas/inductions/Officeworks/Visitor.json'

export default {
    type: 'Officeworks Induction',
    schemas: {
        'O/W Cleaner': Cleaner,
        'O/W Contractor': Contractor,
        'O/W Security Guard': SecurityGuard,
        'O/W Supplier/Merchandiser': SupplierMerchandiser,
        'O/W Unregister Contractor': UnregisterContractor,
        'O/W Visitor': Visitor
    },
}