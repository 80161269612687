<template>
  <div class="mb-4">
    <h3 class="text-center mb-4 text-lg">{{ heading }}</h3>
    <select v-model="selectedSchema" class="w-11/12 block mx-auto" @change="schemaSelected">
      <option :value="null" disabled hidden selected>Select schema</option>
      <option
          v-for="([key, schema]) in Object.entries(schemas)"
          :value="{ name: key, schema: schema.data?.schema || schema.template?.components, maps: schema.template?.maps, equations: schema.template?.equations }"
      >
        {{ key }}
      </option>
    </select>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { EquationsType, errorMessage, MapsType, SchemaType } from "@verifiedit/form-generator";

export default defineComponent({
    name: 'SchemaSelector',

    props: {
      heading: {
        type: String,
        default: ''
      },

      schemas: {
        type: Object,
        default: () => null,
      },
    },

    emits: ['schemaSelected'],

    setup(props, { emit }) {
      const selectedSchema = ref<{name: string, schema: (SchemaType & errorMessage)[], maps: MapsType, equations: EquationsType}|null>(null)

      function schemaSelected() {
        emit('schemaSelected', selectedSchema.value?.name, selectedSchema.value?.schema, selectedSchema.value?.maps, selectedSchema.value?.equations)
      }

      return {
        schemaSelected,
        selectedSchema,
      }
    },
  })
</script>
