import * as Contractor from '../schemas/inductions/Westpac/Contractor.json'
import * as UnregisterContractor from '../schemas/inductions/Westpac/UnregisterContractor.json'
import * as CleanerSupplier from '../schemas/inductions/Westpac/CleanerSupplierVisitor.json'

export default {
    type: 'Westpac Induction',
    schemas: {
        'Westpac Contractor': Contractor,
        'Westpac Unregister Contractor': UnregisterContractor,
        'Westpac Cleaner': CleanerSupplier,
        'Westpac Supplier': CleanerSupplier,
        'Westpac Visitor': CleanerSupplier,
    }
}