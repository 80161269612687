<template>
  <div
    class="bg-white drop-shadow-md filter font-light font-normal font-sans not-italic pb-4 pt-6 px-6 text-center text-grey-900 text-lg"
  >
    <h1 v-if="title">
      {{ title }}
    </h1>
    <h2 v-if="subTitle" class="pt-2 text-center text-sm">{{ subTitle }}</h2>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'TitleBanner',
    props: {
      subTitle: {
        type: String,
        default: '',
      },

      title: {
        type: String,
        default: '',
      },
    },
  })
</script>
