<template>
  <div>
    <SiteBanner site-name="Test site" site-address="1 Test street" />
    <h2 class="my-2 pt-1 pb-4 text-center text-lg">
      {{ serviceFormName }}
    </h2>
    <div class="ml-auto mr-auto mt-4 mb-20" :class="{'w-11/12' : !formIsFullWidth}">
      <FormGenerator
          :schema="schema"
          :maps="schemaMaps"
          :equations="schemaEquations"
          @form-changed="formChangedEventHandler"
          @update-component-visibility="updateComponentVisibilityEventHandler"
          @visibility-changed="updateVisibleComponentsEventHandler"
          @persist-photo="storePhotoEventHandler"
          @remove-photo="removePhotoEventHandler"
          @trolley-collection-added="saveTrolleyCollection"
          @trolley-collection-removed="removeTrolleyCollection"
      />
      <div class="mx-auto mt-4" :class="{'w-11/12' : formIsFullWidth}">
        <BaseButton
          :key="buttonKey"
          action="Submit"
          type="submit"
          @click.once="submitForm"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, nextTick, onMounted, PropType, reactive, ref, toRef } from "vue";
import SiteBanner from "../components/SiteBanner.vue";
import BaseButton from "../components/BaseButton.vue";
import {
  Answer,
  checkAnswers,
  EquationsType,
  errorMessage,
  FormGenerator,
  ImageAnswer,
  ImageType,
  MapsType,
  removePhoto,
  SchemaType,
  scrollToFirstError,
  storePhoto,
  TrolleyCollection,
  TrolleyCollectionInput,
  updateComponentVisibility,
  updateVisibleComponents,
  validateFormSubmission
} from "@verifiedit/form-generator";

export default defineComponent({
  name: "ServiceForm",
  components: {BaseButton, FormGenerator, SiteBanner},

  props: {
    formIsFullWidth: {
      type: Boolean,
      default: false
    },

    serviceFormName: {
      type: String,
      default: 'Service name placeholder'
    },
    schemaProp: {
      type: Array as PropType<(SchemaType & errorMessage)[]>,
      default: () => []
    },
    schemaMaps: {
      type: Object as PropType<MapsType>,
      default: () => ({})
    },
    schemaEquations: {
      type: Object as PropType<EquationsType>,
      default: () => ({})
    },
  },

  setup(props, {emit}) {
    const buttonKey = ref(0)
    const answers: Map<string, Answer> = reactive(new Map())
    const schema = toRef(props, 'schemaProp')
    const visibleComponents = ref<string[]>([])

    onMounted(() => {
      schema.value.forEach(component => {
        component.errorMessage = ''
      })
    })

    function removeSchema() {
      buttonKey.value++
      emit('removeSchema')
    }

    function formChangedEventHandler(
      componentName: string,
      value: string | (string | ImageAnswer | TrolleyCollection)[]
    ) {
      checkAnswers(componentName, value, schema.value, answers)
    }

    function convertArrayToString(
        array: (string | number | TrolleyCollection | ImageType | ImageAnswer)[]
    ) {
      if (typeof array[0] === 'object') {
        return '[' + array.map((item) => item.toString()).join(',') + ']'
      }
      return array.join('|')
    }

    function getValueForKey(componentName: string, key: string) {
      let value = ''

      schema.value.forEach((component) => {
        if (component.identifier === componentName && key in component) {
          value = component[key] as string
        }
      })

      return value
    }

    function transformAnswers(answers: Map<string, Answer>) {
      const formData: { [key: string]: { groupedBy?: string, question?: string, value: Answer} } = {}
      for (const [componentName, value] of answers) {
        const question = getValueForKey(componentName, 'question')
        const groupedBy = getValueForKey(componentName, 'groupedBy')
        const template: { groupedBy?: string, question?: string, value: Answer}  = {
          value: '',
        }
        if (question !== '') {
          template['question'] = question
        }

        if (groupedBy !== '') {
          template['groupedBy'] = groupedBy
        }
        Array.isArray(value)
            ? (template['value'] = convertArrayToString(value))
            : (template['value'] = value as string)

        formData[componentName] = template
      }
      return JSON.stringify(formData)
    }


    async function submitForm() {
      buttonKey.value++
      const canSubmit = validateFormSubmission(schema.value, answers, visibleComponents.value)
      if (!canSubmit) {
        await nextTick()
        scrollToFirstError()
        return
      }
      const formData = transformAnswers(answers)
      console.info('Valid submission form data:\n', formData)
      console.info('Valid submission form data (parsed): ', JSON.parse(formData))
    }

    function updateComponentVisibilityEventHandler(
        identifier: string,
        result: { items: string[]; value: boolean },
        key: string
    ) {
      updateComponentVisibility(identifier, result, key, schema.value)
    }

    function updateVisibleComponentsEventHandler(components: Map<string, string>) {
      visibleComponents.value = updateVisibleComponents(components, answers)
    }

    async function storePhotoEventHandler(
        imageData: string,
        imageTypeId: number,
        sequenceOfImage: number,
        identifier: string
    ) {
      await storePhoto(
          imageData,
          imageTypeId,
          sequenceOfImage,
          identifier,
          schema.value,
          randomIntPromise
      )
    }

    async function randomIntPromise(): Promise<number> {
      return Promise.resolve(Math.floor(100000 + Math.random() * 900000))
    }

    async function okResponsePromise(): Promise<number> {
      return Promise.resolve(200)
    }

    async function removePhotoEventHandler(
        imageId: string,
        identifier: string
    ) {
      await removePhoto(
          imageId,
          identifier,
          schema.value,
          answers,
          okResponsePromise
      )
    }

    async function saveTrolleyCollection(
        trolleyCollection: TrolleyCollection,
        component: InstanceType<typeof TrolleyCollectionInput>
    ) {
      component.addCollection(trolleyCollection)
    }

    async function removeTrolleyCollection(
        trolleyCollection: TrolleyCollection,
        component: InstanceType<typeof TrolleyCollectionInput>
    ) {
      component.deleteCollection()
    }

    return {
      buttonKey,
      formChangedEventHandler,
      removePhotoEventHandler,
      removeSchema,
      removeTrolleyCollection,
      saveTrolleyCollection,
      schema,
      storePhotoEventHandler,
      submitForm,
      updateVisibleComponentsEventHandler,
      updateComponentVisibilityEventHandler
    }
  }
})
</script>

<style scoped>

</style>