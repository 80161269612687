import * as Visitor from '../schemas/inductions/Hertz/Visitor.json'
import * as NonRegisteredContractor from '../schemas/inductions/Hertz/NonRegisteredContractor.json'
import * as OfficeCleaner from '../schemas/inductions/Hertz/OfficeCleaner.json'
import * as VehicleCleaner from '../schemas/inductions/Hertz/VehicleCleaner.json'
import * as VehicleMechanic from '../schemas/inductions/Hertz/VehicleMechanic.json'


export default {
    type: 'Hertz Induction',
    schemas: {
        'Hertz Non Registered Contractor': NonRegisteredContractor,
        'Hertz Office Cleaner': OfficeCleaner,
        'Hertz Vehicle Cleaner': VehicleCleaner,
        'Hertz Vehicle Mechanic': VehicleMechanic,
        'Hertz Visitor': Visitor
    }
}