<template>
  <div class="bg-verified-blue grid grid-cols-10 py-5">
    <div class="pl-2">
      <ChevronLeftIcon
        v-if="showBackArrow"
        class="h-6 text-white w-6"
        @click="$emit('removeSchema')"
      />
    </div>
    <div class="back-arrow col-span-8 text-center">
      <VerifiedLogo :height="logoHeight" :width="logoWidth" class="mb-1 mt-1" />
    </div>
    <div class="text-right z-10">

    </div>
  </div>
</template>

<script lang="ts">
  import { ChevronLeftIcon, XMarkIcon } from '@heroicons/vue/24/solid'
  import VerifiedLogo from './VerifiedLogo.vue'
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'NavigationSlot',
    components: { ChevronLeftIcon, VerifiedLogo, XMarkIcon },
    props: {
      showBackArrow: {
        type: Boolean,
        default: false
      }
    },

    setup() {
      const logoHeight = 30
      const logoWidth = 150

      return {
        logoHeight,
        logoWidth,
      }
    },
  })
</script>
