import * as DockEquipment from '../schemas/services/DockEquipment.json'
import * as ElectricalTestAndTag from '../schemas/services/ElectricalTestAndTag.json'
import * as ElectricalTestingTAndTSwB from '../schemas/services/ElectricalTestingT&TSwB.json'
import * as EssentialSafetyMeasures from '../schemas/services/EssentialSafetyMeasures.json'
import * as ExtinguishersAndHoseReels from '../schemas/services/ExtinguishersAndHoseReels.json'
import * as ExtinguishersAndHoseReelsYearlyFrequency from '../schemas/services/ExtinguishersAndHoseReelsYearlyFrequency.json'
import * as FirePumpSets from '../schemas/services/FirePumpSets.json'
import * as GeneralCleaning from '../schemas/services/GeneralCleaning.json'
import * as GeneralElectrical from '../schemas/services/GeneralElectrical.json'
import * as GeneralHydraulic from '../schemas/services/GeneralHydraulic.json'
import * as GeneralMaintenance from '../schemas/services/GeneralMaintenance.json'
import * as GeneralMechanical from '../schemas/services/GeneralMechanical.json'
import * as GeneralPlumbing from '../schemas/services/GeneralPlumbing.json'
import * as GroundStaff from '../schemas/services/GroundStaff.json'
import * as ManualHandlingEquipment from '../schemas/services/ManualHandlingEquipment.json'
import * as MeansOfEgress from '../schemas/services/MeansOfEgress.json'
import * as Repair from '../schemas/services/Repair.json'
import * as ResidualCurrentDevices from '../schemas/services/ResidualCurrentDevices.json'
import * as SmokeAndHeatAlarms from '../schemas/services/SmokeAndHeatAlarms.json'
import * as Standard from '../schemas/services/Standard.json'
import * as StandardWithRequiredServiceDocket from '../schemas/services/StandardWithRequiredServiceDocket.json'
import * as StoreResponsibilities from '../schemas/services/StoreResponsibilities.json'
import * as TrolleyCollection from '../schemas/services/TrolleyCollection.json'
import * as CleaningAudit from '../schemas/services/Officeworks/CleaningAudit.json'

export default {
    type: 'Service forms',
    schemas: {
        'Dock Equipment': DockEquipment,
        'Electrical Test And Tag': ElectricalTestAndTag,
        'Electrical Testing, T&T, SwB': ElectricalTestingTAndTSwB,
        'Essential Safety Measures': EssentialSafetyMeasures,
        'Extinguishers and Hose Reels': ExtinguishersAndHoseReels,
        'Extinguishers and Hose Reels (Yearly)': ExtinguishersAndHoseReelsYearlyFrequency,
        'Fire Pump Sets': FirePumpSets,
        'General Cleaning': GeneralCleaning,
        'General Electrical': GeneralElectrical,
        'General Hydraulic': GeneralHydraulic,
        'General Maintenance': GeneralMaintenance,
        'General Mechanical': GeneralMechanical,
        'General Plumbing': GeneralPlumbing,
        'Ground Staff': GroundStaff,
        'Manual Handling Equipment': ManualHandlingEquipment,
        'Means of Egress': MeansOfEgress,
        'Officeworks Cleaning Audit': CleaningAudit,
        'Repair': Repair,
        'Residual Current Devices': ResidualCurrentDevices,
        'Smoke and Heat Alarms': SmokeAndHeatAlarms,
        'Standard': Standard,
        'Standard with Required Service Docket': StandardWithRequiredServiceDocket,
        'Store Responsibilities': StoreResponsibilities,
        'Trolley Collection': TrolleyCollection,
    }
}